import { UnstyledButton } from '../../ButtonV2/Button';
import { CountryFlagIcon } from '../../CountryFlagIcon/CountryFlagIcon';
import CaretDown from '../../../icons/icons/Navigation/CaretDown';

export interface CountrySelectorTriggerProps {
  countryCode: string;
  toggleCountryMenu: () => void;
}

export default function CountrySelectorTrigger({
  countryCode,
  toggleCountryMenu,
}: CountrySelectorTriggerProps): JSX.Element {
  return (
    <UnstyledButton className='flex items-center' onClick={toggleCountryMenu}>
      <CountryFlagIcon countryCode={countryCode} />
      <div className='pl-2'>
        <CaretDown fill='#969696' width={14} />
      </div>
    </UnstyledButton>
  );
}
