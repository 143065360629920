import {
  AE,
  AT,
  AU,
  AX,
  BE,
  BG,
  BH,
  CA,
  CH,
  CY,
  CZ,
  DE,
  DK,
  EE,
  EU,
  FI,
  FO,
  FR,
  GB,
  GG,
  GL,
  GR,
  HK,
  HR,
  HU,
  IE,
  IL,
  IM,
  IN,
  IS,
  IT,
  JE,
  JP,
  KR,
  KW,
  LT,
  LU,
  LV,
  MX,
  MT,
  NZ,
  QA,
  SA,
  SG,
  TW,
  US,
  ZA,
  MC,
  NL,
  NO,
  PL,
  PT,
  RO,
  SK,
  SI,
  ES,
  SE,
} from 'country-flag-icons/react/3x2';
import React from 'react';
import { cltw } from '../../utils';

/* eslint-disable id-length */
export const regionFlagRegistry: Record<string, React.ComponentType> = {
  AE,
  AT,
  AU,
  AX,
  BE,
  BG,
  BH,
  CA,
  CH,
  CY,
  CZ,
  DE,
  DK,
  EE,
  ES,
  EU,
  FI,
  FO,
  FR,
  GB,
  GG,
  GL,
  GR,
  HK,
  HR,
  HU,
  IE,
  IL,
  IM,
  IN,
  IS,
  IT,
  JE,
  JP,
  KR,
  KW,
  LT,
  LU,
  LV,
  MC,
  MT,
  MX,
  NL,
  NO,
  NZ,
  PL,
  PT,
  RO,
  QA,
  SA,
  SG,
  SE,
  SI,
  SK,
  TW,
  US,
  ZA,
};

export interface FlagComponentProps {
  countryCode: string;
}

export const CountryFlagIcon = ({ countryCode }): JSX.Element | null => {
  const Flag = regionFlagRegistry[countryCode] as React.ElementType;
  if (Flag) {
    return (
      <Flag
        className={cltw(
          'h-auto w-6 rounded-sm outline outline-black/10',
          countryCode,
        )}
        data-testid='country-flag-icon'
      />
    );
  }
  return null;
};
