'use client';

import { useShallow } from 'zustand/react/shallow';
import Pagination from 'ui/components/Pagination/Pagination';
import { useTranslation } from '@whoop/i18n/lang/client';
import { ProductType } from 'ui';
import { AmplitudeEvents } from 'whoop-analytics/lib/types';
import { env } from 'env';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useGetCurrentPage } from '@/hooks/getCurrentPage';
import { SKIP_MEM_SELECT } from '@/lib/constants/experiments';
import { useNavigation } from '@/hooks/navigation';
import { useAnalytics } from '@/hooks/analytics';
import { getQueryParamFromMembership } from '@/lib/utils/skipMembershipSelectHelper';
import { Header } from './Header';

function PaginationHeader(): JSX.Element {
  const { getLocalizedPath, searchParams } = useNavigation();
  const { trackAmplitudeEvent } = useAnalytics();
  const { accessories, cartProducts, toggleIsCartOpen, experimentVariants } =
    useSiteStore(
      useShallow((state) => ({
        accessories: state.accessories,
        region: state.region,
        cartProducts: state.cartProducts,
        toggleIsCartOpen: state.toggleIsCartOpen,
        experimentVariants: state.experimentVariants,
      })),
    );

  const currentPage = useGetCurrentPage();
  const { t } = useTranslation('joinLandingPage');
  const isSkipMemExperimentEligible =
    experimentVariants[SKIP_MEM_SELECT] &&
    experimentVariants[SKIP_MEM_SELECT] !== 'control';

  const membershipInCart = cartProducts.find(
    (product) => product.item.product_type === ProductType.MEMBERSHIP,
  );
  const isMembershipInCart = Boolean(membershipInCart);

  const showAccessories = accessories?.length;

  const optionsItems = {
    landing: {
      label: '1',
      link: getLocalizedPath(''),
      enabled: true,
      desktopText: t('header.customize'),
    },
    membership: {
      label: '2',
      link: getLocalizedPath('membership'),
      enabled: false,
      desktopText: t('header.chooseMembership'),
    },
    family: {
      label: '2',
      link: getLocalizedPath('family'),
      enabled: true,
      desktopText: t('header.chooseMembership'),
    },
    accessories: {
      label: '3',
      link: getLocalizedPath('accessories'),
      enabled: false,
      desktopText: t('header.addAccessories'),
    },
  };

  let options = () => {
    let items = [optionsItems.landing, optionsItems.membership];
    if (currentPage === 'Family') {
      items = items.map((item) =>
        item.link.includes('membership') ? optionsItems.family : item,
      );
    }

    if (showAccessories) {
      if (membershipInCart?.item.membership_type === 'family') {
        items = items.map((item) =>
          item.link.includes('membership') ? optionsItems.family : item,
        );
      }
      items.push(optionsItems.accessories);
    }

    if (isMembershipInCart) {
      items[1].enabled = true;
    } else if (currentPage === 'Membership' || currentPage === 'Family') {
      items[1].enabled = true;
    } else {
      items[1].enabled = false;
    }

    if (showAccessories) {
      if (isMembershipInCart) {
        items[2].enabled = true;
      } else {
        items[2].enabled = false;
      }
    }

    return items;
  };

  const familySkipMembershipEligible =
    searchParams?.get('membership') === 'family' &&
    Boolean(searchParams?.get('size'));
  if (isSkipMemExperimentEligible || familySkipMembershipEligible) {
    const chooseMembershipLink = familySkipMembershipEligible
      ? `${env.NEXT_PUBLIC_WHOOP_URL}/family-membership`
      : `${env.NEXT_PUBLIC_WHOOP_URL}/membership#choose-plan`;
    options = () => {
      const items = [
        {
          label: '1',
          link: chooseMembershipLink,
          enabled: true,
          desktopText: t('header.chooseMembership'),
        },
        {
          label: '2',
          link: getLocalizedPath(''),
          enabled: true,
          desktopText: t('header.customize'),
        },
      ];

      if (showAccessories) {
        items.push(optionsItems.accessories);

        if (currentPage === 'Accessories') {
          items[2].enabled = true;
        } else {
          items[2].enabled = false;
        }
      }

      return items;
    };
  }

  const cartCounter = cartProducts
    .map((product) => product.quantity)
    .reduce((prev, curr) => prev + curr, 0);

  const pagesWithHeader = [
    'Join',
    'Accessories',
    !isSkipMemExperimentEligible && 'Membership',
    !isSkipMemExperimentEligible && 'Family',
  ];
  const showCart = ['Membership', 'Accessories', 'Family'].includes(
    currentPage,
  );
  const showCurrency = currentPage === 'Join';
  const showOrderPageV2Header = currentPage === 'Order';

  const trackClick = (name: string, number: number): void => {
    trackAmplitudeEvent(AmplitudeEvents.ClickedHeader, { name, number });
  };

  return (
    <Header
      cartCounter={cartCounter}
      showCart={showCart}
      showCountrySelector={showCurrency}
      showOrderPageV2Header={showOrderPageV2Header}
      skipMemQueryParam={
        isSkipMemExperimentEligible
          ? getQueryParamFromMembership(cartProducts)
          : ''
      }
      toggleIsCartOpen={toggleIsCartOpen}
    >
      <div className='absolute left-[50vw] flex -translate-x-1/2 justify-center'>
        {pagesWithHeader.includes(currentPage) && accessories !== undefined ? (
          <Pagination
            activeIndex={0}
            items={options()}
            trackHeaderClick={trackClick}
          />
        ) : null}
      </div>
    </Header>
  );
}

export default PaginationHeader;
