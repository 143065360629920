import type { IconProps } from '../../../components/Icon/Icon.util';

export const IcCaretDown = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
}: IconProps): React.ReactNode => {
  const viewBoxSize = 32;

  return (
    <svg
      data-testid='caret-down'
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      {title ? <title>{title}</title> : null}

      <path d='M16.2959 23.9728C16.5889 23.9184 16.8687 23.7824 17.0956 23.5647L28.6975 12.4367C29.1008 12.0498 29.1008 11.4226 28.6975 11.0357L27.9202 10.2902C27.5169 9.90328 26.8629 9.90328 26.4595 10.2902L16 20.3224L5.54051 10.2902C5.13713 9.90327 4.48312 9.90327 4.07975 10.2902L3.30253 11.0356C2.89915 11.4225 2.89916 12.0498 3.30253 12.4367L14.9044 23.5647C15.2826 23.9275 15.8076 24.0635 16.2959 23.9728Z' />
    </svg>
  );
};

export default IcCaretDown;
