import { i18n } from '@whoop/i18n/lang/client';
import { pickLanguage } from '@whoop/i18n/utils/languageHelpers';
import { LANGUAGES_DISPLAY_NAMES } from '@whoop/i18n/types/languages';
import type { Language } from '@whoop/i18n';
import type {
  CountrySelection,
  CountrySelectorCountry,
  CountrySelectorLanguage,
  CountrySelectorRegion,
} from '../types/CountrySelectorTypes';

export const getSelectedLanguage = (
  country: CountrySelectorCountry,
  countrySelection: CountrySelection,
): CountrySelectorLanguage => {
  return (
    country.languages.find(
      (language) => language.languageCode === countrySelection.languageCode,
    ) ?? country.languages[0]
  );
};

export const getSelectedCountry = (
  regions: CountrySelectorRegion[],
  countryCode: string,
): CountrySelectorCountry => {
  const arrayOfAllCountries = regions
    .map((country) => country.countries)
    .flat();
  return (
    arrayOfAllCountries.find(
      (country) => country.countryCode === countryCode,
    ) ?? regions[0].countries[0]
  );
};

export const getCountrySelectorRegions = (
  supportedCountriesAndLanguagesByRegion,
): CountrySelectorRegion[] => {
  return supportedCountriesAndLanguagesByRegion.map((region) => {
    return {
      displayName: i18n.t(`countrySelector:${region.regionName}`),
      countries: region.countries
        .map((country) => {
          return {
            displayName: i18n.t(`countrySelector:${country.countryCode}`),
            countryCode: country.countryCode,
            languages: country.languagesCodes
              .map((language: string) => {
                return {
                  displayName: LANGUAGES_DISPLAY_NAMES[language],
                  languageCode: language,
                };
              })
              .sort((languageA, languageB) =>
                languageA.displayName.localeCompare(languageB.displayName),
              ),
          };
        })
        .sort((countryA, countryB) =>
          countryA.displayName.localeCompare(countryB.displayName),
        ),
    };
  });
};

export const pickLanguageForCountry = (
  country: CountrySelectorCountry,
  preferredLanguages: string[],
): Language => {
  const availableLanguages = country.languages.map(
    (selectorLanguage) => selectorLanguage.languageCode,
  );
  const acceptLanguageHeader = preferredLanguages.join();
  return pickLanguage(availableLanguages, acceptLanguageHeader);
};
