export const NORTH_AMERICA_REGION_CODES = ['US', 'CA', 'MX'];

export const EUROPE_UNITED_KINGDOM_REGION_CODES = ['EU', 'UK'];

export const MIDDLE_EAST_AFRICA_REGION_CODES = [
  'BH',
  'IL',
  'KW',
  'AE',
  'SA',
  'ZA',
];

export const ASIA_PACIFIC_REGION_CODES = [
  'AU',
  'HK',
  'JP',
  'NZ',
  'KR',
  'SG',
  'TW',
  'IN',
];
