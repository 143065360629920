import type { IconProps } from '../../../components/Icon/Icon.util';

export const LockedBold = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
}: IconProps): React.ReactNode => {
  return (
    <svg
      aria-label={title}
      fill={fill}
      height={height}
      role={role}
      viewBox='0 0 10 11'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M8.20231 5.2623L8.20225 3.40764C8.20225 2.48345 7.85522 1.63589 7.28189 1.01646C6.71077 0.396971 5.89716 -0.000723629 4.99985 9.88548e-07C4.10231 -0.000723629 3.28858 0.396971 2.71752 1.01646C2.14407 1.63589 1.79716 2.48345 1.79698 3.40764V5.2623H1.24647C1.01814 5.2623 0.833008 5.44931 0.833008 5.68016V10.5821C0.833008 10.8129 1.01814 11 1.24647 11H8.75282C8.98127 11 9.16634 10.8129 9.16634 10.5821V5.68016C9.16634 5.44931 8.98127 5.2623 8.75282 5.2623H8.20231ZM3.72261 1.91232C4.06365 1.5717 4.50784 1.37561 4.9999 1.375C5.49164 1.37561 5.93576 1.57157 6.27681 1.91232C6.61524 2.25318 6.83301 2.7322 6.83301 3.27362V5.15625H3.16634V3.27362C3.16634 2.7322 3.38411 2.25318 3.72261 1.91232Z'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  );
};

export default LockedBold;
