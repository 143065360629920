'use client';

import React, { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useGetCurrentPage } from '@/hooks/getCurrentPage';
import { featureFlagNames } from '@/client/featureFlagClient';
import { Region } from '@whoop/i18n';

export interface PromoBannerProps {
  children: React.ReactNode;
  region: Region;
}

export const PageWrapper = ({
  children,
  region,
}: PromoBannerProps): JSX.Element | null => {
  const page = useGetCurrentPage();

  const { siteWidePromoContent, promoContent, siteBannerText, featureFlags } =
    useSiteStore(
      useShallow((state) => ({
        siteWidePromoContent: state.siteWidePromoContent,
        promoContent: state.promoContent,
        siteBannerText: state.siteBannerText,
        featureFlags: state.featureFlags,
      })),
    );

  const israelDisabled = featureFlags[featureFlagNames.DISABLE_IL_JF];

  const showPromoBanner = useMemo(() => {
    // Site banner shows no matter what if it has been set
    if (siteBannerText && page !== 'Order' && page !== 'receipt') {
      return true;
    }
    if (
      !promoContent?.partnership_name &&
      siteWidePromoContent?.bannerText &&
      page !== 'Order'
    ) {
      return true;
    }
    return false;
  }, [page, promoContent, siteWidePromoContent, siteBannerText]);

  return (
    <div
      className={
        showPromoBanner || (region === 'IL' && israelDisabled)
          ? 'pt-32'
          : 'pt-16'
      }
    >
      {children}
    </div>
  );
};
