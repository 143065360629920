'use client';

import { useTranslation } from '@whoop/i18n/lang/client';
import dayjs from 'dayjs';
import { Button } from 'ui/components/ButtonV2/Button';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { getCookie, setCookie } from 'cookies-next';
import type { Language, Region } from '@whoop/i18n';

interface GDRPBannerProps {
  region: Region;
  language: Language;
}

export default function GDRPBanner({
  region,
  language,
}: GDRPBannerProps): JSX.Element | null {
  const gdprCookieString = 'gdpr_accepted';
  const oneWeekFromNow = dayjs().add(7, 'day').toDate();
  const { t } = useTranslation('joinLandingPage', language);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const gdprCookie = getCookie(gdprCookieString);
    if (region === 'EU' && gdprCookie === undefined) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
      hideBanner();
    }
  }, []);

  const hideBanner = () => {
    const gdprBanner = document.getElementById('gdpr-banner');
    if (gdprBanner) {
      gdprBanner.style.display = 'none';
    }
  };

  const onClick = (preference: string) => {
    setCookie(gdprCookieString, preference, {
      expires: oneWeekFromNow,
    });
    setTimeout(hideBanner, 300);
    setShowBanner(false);
  };

  if (region !== 'EU') return null;

  return (
    <div
      className={clsx(
        'bg-gray-a800 z-drawer fixed top-0 w-full flex-row p-4 opacity-0 transition-opacity duration-300',
        showBanner ? 'opacity-100' : 'opacity-0',
      )}
      id='gdpr-banner'
    >
      <div className='flex flex-col items-center justify-center gap-5 sm:flex-row'>
        <div className='text-md text-center font-normal tracking-wide text-white sm:text-left'>
          {t('gdprBanner.title')}
        </div>
        <div className='flex items-center gap-2'>
          <Button
            aria-label='Accept cookies'
            className='border-none'
            name={t('gdprBanner.gotIt')}
            onClick={() => onClick('1')}
            size='medium'
            variant='primary'
          >
            {t('gdprBanner.gotIt')}
          </Button>
        </div>
      </div>
    </div>
  );
}
