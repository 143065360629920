import React from 'react';
import CountrySelector from 'ui/components/CountrySelector/CountrySelector';
import type { CountrySelection } from 'ui/types/CountrySelectorTypes';
import { useTranslation } from '@whoop/i18n/lang/client';
import { getCountrySelectorRegions } from 'ui/utils/countrySelectorHelper';
import { getSupportedCountriesAndLanguagesByRegion } from '@whoop/i18n/utils/countrySelectorHelpers';
import { AmplitudeActions, AmplitudeEvents } from 'whoop-analytics/lib/types';
import { useShallow } from 'zustand/react/shallow';
import { useAnalytics } from '@/hooks/analytics';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useNavigation } from '@/hooks/navigation';

export default function CountrySelectorWrapper(): JSX.Element {
  const regions = getCountrySelectorRegions(
    getSupportedCountriesAndLanguagesByRegion(),
  );

  const { changeSiteCountryAndLanguage } = useNavigation();
  const { trackAmplitudeEvent } = useAnalytics();
  const { t } = useTranslation('countrySelector');
  const { country, language } = useSiteStore(
    useShallow((state) => ({
      country: state.country,
      language: state.language,
    })),
  );

  const handleSelection = (newSelection: CountrySelection) => {
    trackAmplitudeEvent(AmplitudeEvents.JoinFlowCountrySelector, {
      action: AmplitudeActions.CountrySelectorActionChangeSite,
      from_country: country,
      to_country: newSelection.countryCode,
      from_language: language,
      to_language: newSelection.languageCode,
    });
    changeSiteCountryAndLanguage(
      newSelection.countryCode,
      newSelection.languageCode,
    );
  };

  const handleToggle = (isOpen: boolean) => {
    trackAmplitudeEvent(AmplitudeEvents.JoinFlowCountrySelector, {
      action: isOpen
        ? AmplitudeActions.CountrySelectorActionClose
        : AmplitudeActions.CountrySelectorActionOpen,
    });
  };

  return (
    <CountrySelector
      changeLanguageText={t('Change Language')}
      countrySelectorTitle={t('Choose Your Shipping Country')}
      languageSelectorTitle={t('Choose Your Language')}
      onSelect={handleSelection}
      onToggle={handleToggle}
      previousSelection={{ countryCode: country, languageCode: language }}
      regions={regions}
    />
  );
}
