import { i18n } from '@whoop/i18n/lang/client';
import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import type { SiteFormatValues } from '@whoop/i18n/utils/priceHelpers';
import { formatPriceWithSiteValues } from '@whoop/i18n/utils/priceHelpers';
import { shouldExcludeRenewalCopy } from '@whoop/i18n/utils/i18nHelpers';
import type { Product, Variant } from 'services/generated/commerce-service';
import type {
  PromoCode,
  MembershipProduct,
  CartProduct,
  MembershipType,
} from '../types';
import type { BadgeProps } from '../components';
import { getTotalCartDiscount } from './promotionHelpers';

export interface MembershipCardSubtextElement {
  text: string;
  highlighted?: boolean;
  striked?: boolean;
  newLine?: boolean;
  clickAction?: () => void;
}

interface MembershipCardAmounts {
  displayPrice: number;
  strikedPrice?: number;
  perMonthPrice?: number;
  percentSavingsOverMonthly?: number;
  amountSavingsOverMonthly?: number;
}

const formatDisplayPrice = (
  price: number,
  siteValues: SiteFormatValues,
): string => {
  return formatPriceWithSiteValues(price, siteValues, { showCents: false });
};

export const getMembership = (
  type: MembershipType,
  memberships?: MembershipProduct[],
  trialMonths?: number,
): MembershipProduct | undefined => {
  return memberships?.find((membership) => {
    switch (type) {
      case 'trial':
        return membership.membership_type === 'trial';
      case 'monthly':
        return (
          membership.trial_months === 1 &&
          membership.membership_type === 'monthly'
        );
      case 'prepaid':
        return (
          membership.trial_months === trialMonths &&
          membership.membership_type === 'prepaid'
        );
      default:
        return false;
    }
  });
};

// Ecomm method
export const getMembershipProductV2 = (
  key: string,
  memberships: Product[],
): Product | undefined => {
  return memberships.find((membership) => membership.key === key);
};

// Ecomm method
export const getMembershipVariantV2 = (
  key: string,
  memberships: Product[],
): Variant | undefined => {
  return memberships.find((membership) => membership.key === key)?.variants[0];
};

// Ecomm method
export const hasMembershipKey = (
  key: string,
  memberships: Product[],
): boolean => {
  return memberships.some((membership) => membership.key === key);
};

export const getMembershipFromSku = (
  sku: string,
  memberships: MembershipProduct[],
): MembershipProduct | undefined => {
  return memberships.find((membership) => membership.id === sku);
};

export const getMembershipDiscount = (
  promoCode: PromoCode,
  membership: MembershipProduct,
  currency: Currency,
): number => {
  const membershipAsCartProduct = {
    item: membership,
    quantity: 1,
  } as CartProduct;
  const membershipDiscount = getTotalCartDiscount(
    promoCode,
    [membershipAsCartProduct],
    currency,
  );

  return membershipDiscount;
};

export const getPerMonthPrice = (
  displayPrice: number,
  trialMonths: number,
): number => {
  return Math.round(displayPrice / trialMonths);
};

interface PercentSavingsOptions {
  baseRatePrice: number;
  displayPrice: number;
  floor?: boolean;
}
export const getPercentSavings = ({
  baseRatePrice,
  displayPrice,
  floor = false,
}: PercentSavingsOptions): number => {
  const percentSavings = ((baseRatePrice - displayPrice) / baseRatePrice) * 100;
  return floor ? Math.floor(percentSavings) : percentSavings;
};

interface SavingValuesOptions {
  monthlyMembershipPrice?: number;
  membershipMonths: number;
  displayPrice?: number;
  isDiscounted: boolean;
}
interface SavingsValues {
  percentSavings?: number;
  amountSavings?: number;
}
export const getSavingsValues = ({
  monthlyMembershipPrice,
  membershipMonths,
  displayPrice,
  isDiscounted,
}: SavingValuesOptions): SavingsValues => {
  let percentSavings: number | undefined;
  let amountSavings: number | undefined;
  if (monthlyMembershipPrice !== undefined && displayPrice !== undefined) {
    const baseRatePrice = monthlyMembershipPrice * membershipMonths;
    if (isDiscounted) {
      amountSavings = baseRatePrice - displayPrice;
    }
    percentSavings = getPercentSavings({
      baseRatePrice,
      displayPrice,
      floor: true,
    });
  }
  return { percentSavings, amountSavings };
};

export const getCardAmounts = (
  membership: MembershipProduct,
  allMemberships: MembershipProduct[],
  currency: Currency,
  promoCode?: PromoCode,
): MembershipCardAmounts => {
  const originalPrice = membership.display_price;
  const membershipDiscount = promoCode
    ? getMembershipDiscount(promoCode, membership, currency)
    : 0;
  const displayPrice = originalPrice - membershipDiscount;
  const strikedPrice = membershipDiscount && originalPrice;

  const membershipType = membership.membership_type;
  const monthlyMembership = getMembership('monthly', allMemberships);
  if (membershipType !== 'prepaid') {
    return { displayPrice, strikedPrice };
  }

  const trialMonths = membership.trial_months;
  const perMonthPrice = getPerMonthPrice(displayPrice, trialMonths);
  if (!monthlyMembership) {
    return { displayPrice, strikedPrice, perMonthPrice };
  }

  const baseRatePrice = monthlyMembership.display_price * trialMonths;

  let percentSavings, amountSavings;
  if (strikedPrice) {
    amountSavings = baseRatePrice - displayPrice;
  } else {
    percentSavings = getPercentSavings({
      baseRatePrice,
      displayPrice,
      floor: true,
    });
  }

  return {
    displayPrice,
    strikedPrice,
    perMonthPrice,
    percentSavingsOverMonthly: percentSavings,
    amountSavingsOverMonthly: amountSavings,
  };
};

const getCardBadge = (
  membership: MembershipProduct,
  //TODO: use cyber5 flag once it's set up
  isCyber5On: boolean,
): BadgeProps | undefined => {
  const membershipType = membership.membership_type;
  if (membershipType === 'trial') {
    return { color: 'black', label: i18n.t('orderPage:freeTrial') };
  }
  const months = membership.trial_months;
  const hasNoBadge = membershipType === 'monthly' || ![12, 24].includes(months);
  if (hasNoBadge) return;
  if (isCyber5On) {
    return { color: 'gradient-gold', label: i18n.t('orderPage:cyber5') };
  }
  return {
    color: 'red',
    label:
      months === 12
        ? i18n.t('orderPage:mostPopular')
        : i18n.t('orderPage:bestValue'),
  };
};

export const getPriceSubtext = (
  membership: MembershipProduct,
  cardAmounts: MembershipCardAmounts,
  currency: Currency,
  language: Language,
  region: Region,
): MembershipCardSubtextElement[] => {
  const priceSubtext: MembershipCardSubtextElement[] = [];

  switch (membership.membership_type) {
    case 'monthly':
      priceSubtext.push({ text: i18n.t('orderPage:perMonthNoValue') });
      break;
    case 'prepaid':
      if (cardAmounts.strikedPrice) {
        priceSubtext.push({
          text: formatDisplayPrice(cardAmounts.strikedPrice, {
            currency,
            language,
            region,
          }),
          striked: true,
        });
      }
      break;
    case 'trial':
    default:
      break;
  }

  return priceSubtext;
};

interface MembershipTitleSubtitleOptions {
  currency: Currency;
  language: Language;
  region: string;
  asAGift: boolean;
}

export const getMembershipTitleAndSubtitle = (
  membership: MembershipProduct,
  { currency, language, region, asAGift }: MembershipTitleSubtitleOptions,
  page?: string,
): MembershipProduct & { titleLine2: string; title: string } => {
  let title;
  let titleLine2;
  let text;
  switch (membership.membership_type) {
    case 'monthly':
      title = i18n.t('orderPage:payMonthlyPerMonth', {
        price: formatDisplayPrice(membership.display_price, {
          currency,
          language,
          region,
        }),
      });
      titleLine2 = i18n.t('orderPage:payMonthly');
      break;
    case 'prepaid':
      if (
        (region &&
          shouldExcludeRenewalCopy(region) &&
          membership.trial_months === 12) ||
        (asAGift && membership.trial_months === 12)
      ) {
        text = i18n.t(
          `orderPage:${membership.trial_months}Month-NonRenewalMembership`,
        );
      } else {
        text = i18n.t(`orderPage:${membership.trial_months}MonthMembership`);
      }
      break;
    case 'trial':
      text = i18n.t('orderPage:1MonthFreeTrial');
      break;
    case 'family':
      text =
        page === 'Membership'
          ? i18n.t('orderPage:familyMembership')
          : i18n.t('orderPage:12MonthMembership');
      break;
  }

  if (text) {
    const { line1: _title, line2: _titleLine2 } = getTitlesLines(text);
    title = _title;
    titleLine2 = _titleLine2;
  }

  return {
    ...membership,
    titleLine2,
    title,
  };
};

export const getTitlesLines = (
  text: string,
): { line1: string; line2?: string } => {
  const maxLength = 10;
  const trimmedText = text.trim();

  if (trimmedText.length <= maxLength) {
    return { line1: trimmedText };
  }

  //Separates first word
  let firstPart =
    trimmedText.indexOf(' ') !== -1
      ? trimmedText.slice(0, trimmedText.indexOf(' '))
      : trimmedText;
  const _secondPart = trimmedText.slice(firstPart.length).trim();
  let secondPart: string | undefined =
    _secondPart.length === 0 ? undefined : _secondPart;

  //If more than one word fits the maxLength we include those too
  const lastSpaceIndex = trimmedText.lastIndexOf(' ', maxLength);
  if (lastSpaceIndex !== -1) {
    firstPart = trimmedText.slice(0, lastSpaceIndex);
    secondPart = trimmedText.slice(firstPart.length).trim();
  }

  return { line1: firstPart, line2: secondPart };
};
