'use client';

import React from 'react';
import type { Appearance } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import type {
  Currency,
  Language,
} from '@whoop/i18n/types/internationalization';
import { env } from 'env';

interface StripeInitializerProps {
  children: JSX.Element;
  currency: Currency;
  language: Language;
}

const stripe = loadStripe(env.NEXT_PUBLIC_STRIPE_KEY);

// https://docs.stripe.com/currencies#minimum-and-maximum-charge-amounts
const MIN_CHARGE_AMOUNT = 200;

// https://docs.stripe.com/elements/appearance-api
const appearance: Appearance = {
  labels: 'floating',
  theme: 'stripe',
  variables: {
    fontFamily: ' "ProximaNova-Regular", Helvetica, Arial, sans-serif',
    fontLineHeight: '1.5rem',
    fontSizeBase: '1rem',
    borderRadius: '0px',
    colorBackground: '#FFFFFF',
    colorPrimary: '#C8C8C8',
    colorText: 'black',
  },
  rules: {
    '.Block': {
      backgroundColor: 'var(--colorBackground)',
      boxShadow: 'none',
      padding: '1rem',
      border: 'none',
    },
    '.Input': {
      border: '1px solid var(--colorPrimary)',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      paddingLeft: '.75rem',
      paddingRight: '.75rem',
    },
    '.Input:disabled, .Input--invalid:disabled': {
      color: 'lightgray',
    },
    '.AccordionItem': {
      paddingTop: '12px',
      paddingBottom: '12px',
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: '1px solid lightgray',
      borderBottom: '1px solid lightgray',
      boxShadow: 'none',
    },
    '.AccordionItem--selected': {
      color: 'black',
      colorBackground: 'white',
    },
    '.Label': {
      fontWeight: '500',
      color: '#585858',
    },
  },
};

export default function StripeInitializer({
  children,
  currency,
  language,
}: StripeInitializerProps): JSX.Element | null {
  return (
    <Elements
      options={{
        amount: MIN_CHARGE_AMOUNT, // amount will be reset in Payment Element. Here we provide the min charge amount to prevent error upon initialization
        appearance,
        currency,
        fonts: [
          {
            family: 'ProximaNova-Regular',
            src: 'url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)',
          },
        ],
        loader: 'always',
        locale: language,
        mode: 'payment',
        paymentMethodCreation: 'manual',
      }}
      stripe={stripe}
    >
      {children}
    </Elements>
  );
}
