import clsx from 'clsx';
import Link from 'next/link';

export interface PagingItem {
  enabled?: boolean;
  completed?: boolean;
  label: string; // For a11y and keys - needs to be unique
  desktopText?: string;
  onClick?: () => void;
  link?: string; // Presense of link determines whether it is an <a> tag or a <button> tag
}

interface IPagination {
  items: PagingItem[];
  activeIndex: number;
  hasConnectors?: boolean;
  trackHeaderClick?: (name: string, number: number) => void;
}

/**
 *
 * @param items The items to be displayed. { enabled: boolean, completed: boolean, label: string, desktopText?: string, onClick?: () => void, link?: string }
 * @param activeIndex The index of the active item
 * @param hasConnectors Whether or not to display the connectors between items
 * @returns
 */
function Pagination({
  items,
  activeIndex,
  hasConnectors = false,
  trackHeaderClick,
}: IPagination): React.ReactNode {
  const getPagingNumber = (index: number) => {
    return (
      <span
        className={clsx(
          'flex h-7 w-7 items-center justify-center rounded-full border-2 text-sm font-bold',
          items[index].enabled || index === activeIndex
            ? 'border-accent dark:border-white dark:text-white'
            : 'border-neutral-300 text-neutral-300 dark:border-white/30 dark:text-white/30',
        )}
      >
        {index + 1}
      </span>
    );
  };

  const getPagingDesktopText = (pagingItem: PagingItem) =>
    pagingItem.desktopText ? (
      <span className='hidden pl-2 text-xs font-bold uppercase tracking-wider md:inline-block'>
        {pagingItem.desktopText}
      </span>
    ) : null;

  const getPagingKey = (pagingItem: PagingItem) =>
    pagingItem.label.toLowerCase().replaceAll(' ', '');

  const getSeparatorStyles = (index: number): string => {
    if (items[index].enabled || index === activeIndex) {
      return 'border-white';
    }
    return 'border-neutral-700';
  };

  return (
    <nav aria-label='Pagination'>
      <ul className='flex'>
        {items.map((pagingItem, index) => (
          <div
            className='flex flex-row items-center'
            key={`${getPagingKey(pagingItem)}-${index + 1}`}
          >
            {hasConnectors && index !== 0 ? (
              <hr
                className={clsx('w-6 border-t-2', getSeparatorStyles(index))}
                role='presentation'
              />
            ) : null}
            <li
              className={clsx(
                'text-sm',
                hasConnectors ? 'p-1' : 'p-2',
                !pagingItem.enabled && 'pointer-events-none',
              )}
              key={`${getPagingKey(pagingItem)}-${index + 1}`}
            >
              {pagingItem.link ? (
                <Link
                  aria-current={index === activeIndex ? 'page' : undefined}
                  aria-label={
                    pagingItem.label ? pagingItem.label : `Page ${index + 1}`
                  }
                  className={clsx(
                    'focus:ring-blue-a800 flex items-center justify-center  outline-none ring-offset-4 focus:ring-2',
                    pagingItem.enabled ? 'text-accent' : 'text-neutral-300',
                  )}
                  href={pagingItem.enabled ? pagingItem.link : '#'}
                  onClick={() =>
                    trackHeaderClick &&
                    trackHeaderClick(pagingItem.desktopText ?? '', index + 1)
                  }
                >
                  {getPagingNumber(index)}
                  {getPagingDesktopText(pagingItem)}
                </Link>
              ) : (
                <button
                  disabled={!pagingItem.enabled}
                  onClick={() =>
                    pagingItem.enabled &&
                    pagingItem.onClick &&
                    pagingItem.onClick()
                  }
                  type='button'
                >
                  {getPagingNumber(index)}
                  {getPagingDesktopText(pagingItem)}
                </button>
              )}
            </li>
          </div>
        ))}
      </ul>
    </nav>
  );
}

export default Pagination;
