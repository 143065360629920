import React, { useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  CustomAccordionTrigger,
} from '../../AccordionV3/Accordion';
import CaretDown from '../../../icons/icons/Navigation/CaretDown';
import { UnstyledButton } from '../../ButtonV2/Button';
import { Separator } from '../../Separator/Separator';
import type {
  CountrySelectorCountry,
  CountrySelectorRegion,
} from '../../../types/CountrySelectorTypes';

interface SupportedCountriesAccordionProps {
  regions: CountrySelectorRegion[];
  onSelect: (country: CountrySelectorCountry) => void;
}

export default function SupportedCountriesAccordion({
  regions,
  onSelect,
}: SupportedCountriesAccordionProps): JSX.Element {
  const [openRegion, setOpenRegion] = useState<string>();

  const handleSelect = (country: CountrySelectorCountry) => {
    onSelect(country);
    setOpenRegion('');
  };

  return (
    <Accordion
      className='w-full'
      collapsible
      onValueChange={(region) => setOpenRegion(region)}
      type='single'
      value={openRegion}
    >
      {regions.map((region) => (
        <>
          <AccordionItem
            className='px-3'
            key={`country-selector-region-${region.displayName}`}
            value={region.displayName}
          >
            <CustomAccordionTrigger
              className='group py-5 text-xl font-semibold'
              icon={
                <div className='transition-transform group-data-[state=open]:rotate-180'>
                  <CaretDown fill='#969696' width={28} />
                </div>
              }
            >
              {region.displayName}
            </CustomAccordionTrigger>
            <AccordionContent className='grid w-full grid-cols-2 gap-4 pb-5'>
              {region.countries.map((country) => (
                <UnstyledButton
                  className='h-12 w-full text-left text-base'
                  key={country.countryCode}
                  onClick={() => handleSelect(country)}
                >
                  {country.displayName}
                </UnstyledButton>
              ))}
            </AccordionContent>
          </AccordionItem>
          <Separator />
        </>
      ))}
    </Accordion>
  );
}
