import { cltw } from '../../utils';
import { PromoTheme } from '../../types/PromoCodeThemes';
import DOMPurify from 'dompurify';

export interface PromoBannerProps {
  content: string;
  theme?: PromoTheme;
  tagText?: string;
}

export const PromoBanner = ({
  content,
  theme = 'black-palette',
  tagText,
}: PromoBannerProps): JSX.Element | null => {
  return (
    <div
      data-testid='promo-banner-container'
      className={cltw(
        'animate-accordion-down flex w-full justify-center transition-all',
        theme,
      )}
    >
      <div
        data-testid='promo-banner-content'
        className={cltw(
          'flex w-full max-w-3xl items-center px-6 py-4 text-center text-sm sm:text-lg md:px-10 md:text-lg md:leading-6',
          tagText ? 'justify-between' : 'justify-center',
        )}
      >
        <div
          className={cltw(tagText ? 'pr-2 text-left' : 'text-center')}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        />
        {tagText && (
          <div className='w-max whitespace-nowrap rounded-md bg-white p-2 text-xs font-bold text-black md:text-sm'>
            {tagText}
          </div>
        )}
      </div>
    </div>
  );
};
