'use client';

import React, { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { PromoBanner } from 'ui/components/PromoBanner/PromoBanner';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useGetCurrentPage } from '@/hooks/getCurrentPage';
import { featureFlagNames } from '@/client/featureFlagClient';
import contentfulClient from '@/client/contentfulClient';
import { JF_SHIPPING_OPTIONS } from '@/lib/constants/experiments';
import { useTranslation } from '@whoop/i18n/lang/client';

export const PromoBannerWrapper = (): JSX.Element | null => {
  const page = useGetCurrentPage();
  const {
    siteWidePromoContent,
    promoContent,
    featureFlags,
    language,
    region,
    siteBannerText,
    setSiteBannerText,
    experimentVariants,
  } = useSiteStore(
    useShallow((state) => ({
      siteWidePromoContent: state.siteWidePromoContent,
      promoContent: state.promoContent,
      featureFlags: state.featureFlags,
      language: state.language,
      region: state.region,
      siteBannerText: state.siteBannerText,
      setSiteBannerText: state.setSiteBannerText,
      experimentVariants: state.experimentVariants,
    })),
  );
  const siteBannerId = 'SITEBANNER';
  const { t } = useTranslation('joinLandingPage', language);

  const getSiteBannerText = async () => {
    if (featureFlags[featureFlagNames.DISABLE_IL_JF] && region === 'IL') {
      const israelText = t('notShippingToIsrael');
      setSiteBannerText(israelText);
    } else if (
      featureFlags[featureFlagNames.SITE_BANNER] &&
      experimentVariants[JF_SHIPPING_OPTIONS] === 'promo'
    ) {
      try {
        const bannerText = await contentfulClient.getSiteWideBannerContent({
          language,
          bannerId: siteBannerId,
        });
        setSiteBannerText(bannerText);
      } catch (_err) {
        // If getting content fails, just don't show the banner
      }
    }
  };

  useEffect(() => {
    if (!siteBannerText) {
      getSiteBannerText();
    }
  }, [featureFlags, siteBannerText]);

  if (page === 'Order' || page === 'receipt') {
    return null;
  }

  // If site wide banner content, show even if partnership
  if (siteBannerText) {
    return <PromoBanner content={siteBannerText} />;
  }

  return !promoContent?.partnership_name && siteWidePromoContent?.bannerText ? (
    <PromoBanner content={siteWidePromoContent.bannerText} />
  ) : null;
};
